@charset "utf-8";
/*
Make sure that assets/css/styles_feeling_responsive.css imports this file
*/

.thecard {
    border: 2px;
    margin-top:5px;
    margin-bottom:5px;
    border-style: solid;
    border-color: black;
}

.thecard p, .thecard ul {  
    margin-bottom:5px;
}

.fullpage-cardart {
    max-width: 110px;
}



.frontpage-div-cardart {
    float: right;
    padding-right: 5px;
    padding-top: 5px;
}

.cardinfo {
}

.cardinfo h3 {
    margin-top:0px !important;
}

.frontpage-cardart {
    height: 120px;
    width: auto;
}

.frontpage-cardname {
    font-size: $font-size-h3; 
    font-family: $header-font-family;
    font-weight: normal;
    padding: 0;
    margin: 1.152em 0 0 0;
}

.bloglistimage {
    float:right;
    max-width:200px;
}

.cardtable {
/* 
MAKE TABLES WORK ON SMALL SCREENS
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/ 
@media 
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

	/* Force table to not be like tables anymore */
	table, thead, tbody, th, td, tr { 
		display: block !important; 
	}
	
	/* Hide table headers (but not display: none;, for accessibility) */
	thead tr { 
		position: absolute !important;
		top: -9999px !important;
		left: -9999px !important;
	}
	
    tr { border: 1px solid #ccc !important; 
    }

	td { 
		/* Behave  like a "row" */
		border: none !important;
		border-bottom: 1px solid #eee !important; 
		position: relative !important;
        padding-left: 50% !important;
        float:inherit !important;
        text-align: inherit !important;
	}
	
	td:before { 
		/* Now like a table header */
		position: absolute !important;
		/* Top/left values mimic padding */
		top: 6px !important;
		left: 6px !important;
		width: 45% !important; 
		padding-right: 10px !important; 
		white-space: nowrap !important;
	}
	
	/*
	Label the data
    */
	td:nth-of-type(1):before { content: ""; }
	td:nth-of-type(2):before { content: "Card Name"; }
	td:nth-of-type(3):before { content: "Upfront Bonuses"; }
	td:nth-of-type(4):before { content: "Spending Benefits"; }
	td:nth-of-type(5):before { content: "Other Key Benefits"; }
	td:nth-of-type(6):before { content: "Annual Fee"; }
    td:nth-of-type(7):before { content: "How to Use"; }

} /* end @media */
} /* end .cardtable */

.skiminor {
	color:grey;} /* Greys smaller ski resorts */

